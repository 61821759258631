<ul class:is-mobile={isMobile}>
  <li>
    {#if path === urlFor.todays()}
      <span>{$_('todays')}</span>
    {:else}
      <a rel="prefetch" href={urlFor.todays()}>{$_('todays')}</a>
    {/if}
  </li>
  <li>
    {#if path === urlFor.library()}
      <span>{$_('library')}</span>
    {:else}
      <a rel="prefetch" href={urlFor.library()}>{$_('library')}</a>
    {/if}
  </li>
  {#if $session.lang != 'ru'}
  <li>
    {#if path === urlFor.genres()}
      <span>{$_('genres')}</span>
    {:else}
      <a href={urlFor.genres()}>{$_('genres')}</a>
    {/if}
  </li>
  {/if}
  {#if $session.user}
    <li>
      {#if path === urlFor.profile()}
        <span>{$_('my_books')}</span>
      {:else}
        <a rel="prefetch" href={urlFor.profile()}>{$_('my_books')}</a>
      {/if}
    </li>
    {#if isMobile}
      <li>
        <span class="logout" on:click|preventDefault={logOut}>{$_('log_out')}</span>
      </li>
    {/if}
  {/if}
</ul>

<style lang="stylus">
  ul
    display: inline-block
    margin: 0
    list-style: none
    padding: 0

    @media $bp.desktop
      &:not(.is-mobile)
        font-weight: bold
        margin-left: 60px

        a,
        span
          padding-bottom: 2px

        span
          border-bottom: 2px solid $violet

        li
          display: inline-block
          margin-right: 46px

  .is-mobile
    min-width: 155px
    margin-top: -24px
    padding-top: 24px

    li
      margin: 0 -30px
      padding: 0 30px

    li,
    a
      display: block

    li:not(:last-child)
      padding-bottom: 24px

  a
    color: inherit
    text-decoration: none

  .logout
    cursor: pointer
</style>

<script>
  import { _ } from 'svelte-intl';
  import { stores } from '@sapper/app';
  import { user } from '../stores';

  import * as urlFor from '../helpers/urlFor';

  export let path = '';
  export let isMobile = false;

  const { session } = stores();

  async function logOut() {
    await user.logOut();
    $session.user = null;
  }
</script>
