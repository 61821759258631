import { writable } from 'svelte/store';

import { get } from './helpers';
import { createUser } from './user';

const ALERT_DELAY = 5000;

const createPacks = () => {
  const { subscribe, set } = writable([]);

  return {
    async load() {
      set(await get('/stripe_products'));
    },
    subscribe
  };
};

const createPopup = () => {
  const { subscribe, set } = writable(null);

  return {
    show: (component, props = {}, popupProps = {}) => {
      set({ component, props, popupProps });
    },
    hide: () => set(null),
    subscribe
  };
};

const createAlert = () => {
  const { subscribe, set } = writable(null);

  return {
    show: ({ message, type, delay }) => {
      set({
        type,
        message,
        hidden: false
      });

      setTimeout(() => set({ hidden: true }), delay || ALERT_DELAY);
    },
    hide: () => set(null),
    subscribe
  };
};


export const packs = createPacks();
export const popup = createPopup();
export const alert = createAlert();
export const user = createUser(alert, popup);
export const config = writable({});
