<svelte:body on:click={handleClickOutside()} />

<div class="dropdown" bind:this={dropdown}>
  <div class="dropdown__handler" on:click|preventDefault={toggle}>
    <slot name="handler" />
  </div>
  {#if !hidden}
    <div
      class="dropdown__content"
      on:click={handleClickOutside(autoHide)}
      transition:fly="{{ y: -16, duration: 100 }}"
    >
      <slot name="content" />
    </div>
  {/if}
</div>

<style lang="stylus">
  .dropdown
    display: inline-block
    position: relative

    &__handler
      cursor: pointer

    &__content
      position: absolute
      top: 100%
      right: 0
      z-index: 3
      margin-top: 8px
      padding: 24px 30px
      background: $bg-color
      box-shadow: 0 4px 10px rgba($color, 0.15)
      border-radius: 8px
</style>

<script>
  import { fly } from 'svelte/transition';

  export let hidden = true;
  export let autoHide = true;

  let dropdown;

  function toggle() {
    hidden = !hidden;
  }

  function hide() {
    hidden = true;
  }

  function handleClickOutside(autoHide) {
    return (e) => {
      if (!hidden && (autoHide || !dropdown.contains(e.target))) {
        hide();
      }
    };
  }
</script>
