export const get = (key) => {
  try {
    return JSON.parse(localStorage.getItem(key));
  } catch(e) {
    // ignore
  }
};

export const set = (key, value) => {
  try {
    localStorage.setItem(key, JSON.stringify(value));
  } catch(e) {
    // ignore
  }
};

export const remove = (key) => {
  try {
    localStorage.removeItem(key);
  } catch(e) {
    // ignore
  }
};
