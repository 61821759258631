<header>
  <Logo />
  <div class="main-section">
    <SearchField />
    <Nav path={path} />
  </div>
  <div class="right">
    {#if $session.user && $user}
      <p data-cy="header-balance">
        <a href={urlFor.shop()} class="balance">
          <span id="balance">{$user.account.balance}</span><CoinIcon />
        </a>
      </p>
    {:else}
      <LoginButton />
    {/if}
    <a class="search" aria-label={$_('search')} href={urlFor.search()}>
      <SearchIcon />
    </a>
    {#if $session.user || $session.mobilePlatform}
      <div class="menu">
        <Dropdown>
          <svg
            role="button"
            aria-label={$_('menu')}
            tabindex="0"
            slot="handler"
            width="23"
            height="17"
            viewBox="0 0 23 17"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect width="23" height="3" rx="1.5" fill="#C4C4C4"/>
            <rect y="7" width="23" height="3" rx="1.5" fill="#C4C4C4"/>
            <rect y="14" width="23" height="3" rx="1.5" fill="#C4C4C4"/>
          </svg>
          <div slot="content">
            {#if $session.user}
              <div class="user-info">
                <p>{$session.user.name || $session.user.email}</p>
                <hr>
              </div>
            {/if}
            <Nav path={path} isMobile />
            {#if !$session.user && $session.mobilePlatform}
              <LoginButton isMobile />
            {/if}
          </div>
        </Dropdown>
      </div>
    {/if}
  </div>
</header>

<style lang="stylus">
  header
    box-sizing: border-box
    min-height: 90px
    display: flex
    padding: 22px 22px 22px 24px
    align-items: center

    &>*
      height 100%

  p
    font-weight: bold
    white-space: nowrap
    margin: 0

  .main-section
    width: $main-width
    margin: -0.5em auto 0
    display: none

    @media $bp.desktop
      display: block

  .balance
    color: inherit
    text-decoration: none
    font-weight: bold

    :global(.coin-icon)
      vertical-align bottom
      position relative
      bottom 2px

    span
      vertical-align: bottom
      margin-right: 4px

  .right
    display: flex
    align-items: center
    margin: -0.5em 0 0 auto

    p
      padding-bottom 2px

  .search,
  .menu
    margin-left: 16px

  .search
    @media $bp.desktop
      display: none

  .user-info
    margin: 0 0 16px

    p
      padding: 0
      margin: 0 0 8px

    hr
      border: 1px solid rgba($grey, 0.5)
</style>

<script>
  import { _ } from 'svelte-intl';
  import { stores } from '@sapper/app';
  import { user } from '../stores';

  import Logo from './Logo.svelte';
  import Nav from './Nav.svelte';
  import SearchField from './SearchField.svelte';
  import CoinIcon from './CoinIcon.svelte';
  import SearchIcon from './SearchIcon.svelte';
  import LoginButton from './LoginButton.svelte';
  import Dropdown from './Dropdown.svelte';

  import * as urlFor from '../helpers/urlFor';

  export let path;

  const { session } = stores();
</script>
