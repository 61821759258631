import * as urlFor from '../helpers/urlFor';
import { writable, get as _get } from 'svelte/store';
import { _ } from 'svelte-intl';
import { throttle } from '../helpers/throttle';
import { get, post } from './helpers';
import * as analytics from '../helpers/analytics';

const UPDATE_ACCOUNT_DELAY = 1000;

export const createUser = (alert, popup) => {
  const store = writable(null);
  const { set, update, subscribe } = store;

  const updateAccount = async () => {
    const { user: { account } } = await get('/account');

    update((value) => {
      value.account = account;
      return value;
    });
  };

  const waitUpdateAccount = throttle(updateAccount, UPDATE_ACCOUNT_DELAY);

  const auth = async (payload, csrfToken) => {
    await post('/auth', payload, csrfToken);
    window.postMessage({ type: 'auth-success' }, location.origin);
  };

  const logIn = async (params) => {
    const { default: FirebaseLogin } = await import('../components/FirebaseLogin.svelte');
    popup.show(FirebaseLogin, params || {}, { autoSize: true });
  };

  const logOut = async () => {
    const { csrfToken } = _get(store);

    await post('/logout', null, csrfToken);
    set(null);
  };

  const deferBook = async (input /* { productId: ID } */, title) => {
    const { csrfToken } = _get(store);
    const json = await post('/defer_book', input, csrfToken);

    if (json.errors) {
      alert.show({
        type: 'error',
        message: json.errors.map((error) => error.message).join(', ')
      });
    } else {
      alert.show({
        type: 'success',
        message: _get(_)('book_added_to_favorites', {
          title,
          link: urlFor.deferred()
        })
      });
    }
  };

  const undeferBook = (input /* { productId: ID } */) => {
    const { csrfToken } = _get(store);
    return post('/undefer_book', input, csrfToken);
  };

  const purchasePack = async (pack) => {
    const { account } = _get(store);
    const { default: animateNumber } = await import('../helpers/animateNumber');

    analytics.purchaseCoins();

    animateNumber({
      node: document.querySelector('#balance'),
      finalValue: account.balance + pack.coinsAmount,
      duration: 1000,
      format: '0'
    });

    alert.show({
      type: 'success',
      message: _get(_)('pack_purchased', { name: pack.title })
    });

    waitUpdateAccount();
  };

  const createStripeIntent = (productId) => {
    const { csrfToken } = _get(store);
    return post('/stripe_payment_intent', { productId }, csrfToken);
  };

  const [rentBook, purchaseBook, redeemBook] = [
    { url: '/rent_book', analyticsEvent: analytics.rentBook },
    { url: '/purchase_book', analyticsEvent: analytics.purchaseBook },
    { url: '/redeem_book', analyticsEvent: analytics.redeemBook }
  ].map(({ url, analyticsEvent }) => async (productId, title, price, book) => {
    const { default: animateNumber } = await import('../helpers/animateNumber');

    const { csrfToken, account } = _get(store);
    const json = await post(url, { productId }, csrfToken);

    if (json.errors) {
      alert.show({
        type: 'error',
        message: json.errors.map((error) => error.message).join(', ')
      });
    } else {
      analyticsEvent(book);
      animateNumber({
        node: document.querySelector('#balance'),
        finalValue: account.balance - price,
        duration: 1000,
        format: '0'
      });

      alert.show({
        type: 'success',
        message: _get(_)('book_aquired', {
          title,
          link: urlFor.rented()
        })
      });
    }

    waitUpdateAccount();
  });

  return {
    set,
    subscribe,
    updateAccount,
    waitUpdateAccount,
    logIn,
    logOut,
    auth,
    deferBook,
    undeferBook,
    purchasePack,
    createStripeIntent,
    rentBook,
    purchaseBook,
    redeemBook
  };
};
