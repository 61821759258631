{#if $session.showCookieMessage}
  <div role="alert">
    <p>{$_('cookie_message')}</p>
    <button on:click|preventDefault={close}>
      {$_('i_understand')}
    </button>
  </div>
{/if}

<style lang="stylus">
  div
    font-size: 14px
    display: flex
    position: fixed
    bottom: 0
    left: 0
    right: 0
    z-index: 2
    background: #E1E5E8
    color: #4E5059
    padding: 16px 24px
    flex-direction: column

    @media $bp.desktop
      flex-direction: row

  p
    margin: 1px 8px 1px 0

  button
    cursor: pointer
    color: $violet
    font-size: 14px
    background: transparent
    border: none
    padding: 0
    margin-top: 16px
    text-decoration: underline
    align-self: flex-start
    white-space: nowrap

    @media $bp.desktop
      margin: 0 0 0 auto
</style>

<script>
  import { stores } from '@sapper/app';
  import { _ } from 'svelte-intl';

  const { session } = stores();

  function close() {
    $session.showCookieMessage = false;
    document.cookie = 'accept_cookies=1;max-age=31536000';
  }
</script>
