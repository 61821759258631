<svelte:body on:click={hideHistory} />

<form role="search" on:submit={saveQuery} action={urlFor.search()}>
  <SearchIcon />
  <input
    bind:this={field}
    on:focus|preventDefault={showHistory}
    aria-label={$_('search')}
    autocomplete="off"
    autocorrect="off"
    autocapitalize="off"
    spellcheck="false"
    type="text"
    name="query"
    placeholder={$_('search_placeholder')}
  />
  {#if searchHistory.length && searchHistoryShown}
    <dl bind:this={history}>
      <dt>
        <span>{$_('recent_searches')}</span>
        <svg on:click={removeHistory} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M1 15L15 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"/>
          <path d="M1 1L15 15" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"/>
        </svg>
      </dt>
      <dd>
        <ul>
          {#each searchHistory as query (query)}
            <li>
              <a on:click={selectQuery} href={urlFor.search(query)}>{query}</a>
              <svg on:click|stopPropagation={removeQuery(query)} width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 15L15 1" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"/>
                <path d="M1 1L15 15" stroke="#C4C4C4" stroke-width="2" stroke-linecap="round"/>
              </svg>
            </li>
          {/each}
        </ul>
      </dd>
    </dl>
  {/if}
</form>

<style lang="stylus">
  form
    position: relative
    display: inline-block
    position: relative
    margin-left: 16px

    :global(.search-icon)
      z-index: 1
      position: absolute
      margin-top: -9px
      top: 50%
      left: 16px

  input
    appearance: none
    font-size: 1em
    font-family: $font-lab-grotesque
    background: $field-color
    box-sizing: border-box
    border-radius: 100px
    border: none
    width: 312px
    height: 43px
    padding: 0 16px 0 48px
    outline: 0

    &::placeholder
      color: darken($grey, 10%)

  dl
    position: absolute
    z-index: 2
    top: 51px
    box-sizing: border-box
    width: 100%
    background: $bg-color
    box-shadow: 0 4px 10px rgba($color, 0.1)
    border-radius: 8px
    padding: 0 16px
    margin: 0

  dt
    font-size: 18px
    padding: 10px 0
    font-weight: bold
    border-bottom: 1px solid $grey

  dt,
  li
    display: flex
    align-items: center

    svg
      cursor: pointer

  dd
    padding: 0
    margin: 0

  ul
    color: $warm-grey
    list-style: none
    padding: 0
    margin: 0

  li
    padding: 10px 0

  li:not(:last-child)
    border-bottom: 1px solid $grey

  a,
  span
    flex-grow: 1

  a
    color: inherit
    text-decoration: none
    display: block
    margin-right: 16px
</style>

<script>
  import { onMount } from 'svelte';
  import { _ } from 'svelte-intl';

  import * as LS from '../helpers/localStorage';
  import * as urlFor from '../helpers/urlFor';
  import SearchIcon from './SearchIcon.svelte';

  export let searchHistory = [];
  export let searchHistoryShown = false;

  const SEARCH_HISTORY_KEY = 'searchHistory';
  const SEARCH_HISTORY_LIMIT = 5;

  let history;
  let field;

  function saveQuery() {
    const { value } = field;
    const trimmedValue = value.trim();
    if (!trimmedValue) return;

    const newSearchHistory = [
      trimmedValue,
      ...searchHistory
    ];

    LS.set(SEARCH_HISTORY_KEY, [
      ...new Set(newSearchHistory.slice(0, SEARCH_HISTORY_LIMIT))
    ]);
  }

  function showHistory() {
    searchHistoryShown = true;
  }

  function hideHistory(e) {
    const { target } = e;

    if (!field.contains(target) && (history && !history.contains(target))) {
      e.preventDefault();
      searchHistoryShown = false;
    }
  }

  function selectQuery() {
    searchHistoryShown = false;
  }

  function removeHistory() {
    searchHistory = [];
    LS.remove(SEARCH_HISTORY_KEY);
  }

  function removeQuery(query) {
    return () => {
      const newSearchHistory = searchHistory.filter((q) => q !== query);

      if (newSearchHistory.length) {
        searchHistory = newSearchHistory;
        LS.set(SEARCH_HISTORY_KEY, newSearchHistory);
      } else {
        removeHistory();
      }
    };
  }

  onMount(() => {
    const searchHistoryFromLS = LS.get(SEARCH_HISTORY_KEY);

    if (searchHistoryFromLS && searchHistoryFromLS.length) {
      searchHistory = searchHistoryFromLS;
    }
  });
</script>
