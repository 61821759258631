import { delay } from './delay';

/**
 * Returns function that returns promise that will run fn and resolve with its result
 * after given delay. Any subsequent calls within timeout period will return the same promise
 * so fn will be called only once.
 *
 * @param {function} fn
 * @param {number} timeout in ms
 */
export function throttle(fn, timeout) {
  let memoised = null;

  return () => {
    if (!memoised) {
      memoised = delay(timeout).then(fn);
      memoised.then(() => memoised = null);
    }

    return memoised;
  };
}
