import { get } from 'svelte/store';
import { translate } from 'svelte-intl';

import generateSlug from './generateSlug';
import truncateGenreTitle from './truncateGenreTitle';

export const home = () => '/';

export const library = () => '/library';

export const librarySlider = (slider) => `${library()}/${generateSlug(slider.header)}?id=${slider.id}`;

export const deferred = () => '/profile/deferred';
export const profile = deferred;

export const rented = () => '/profile/rented';

export const book = (book) => `/books/${generateSlug(book.title)}?id=${book.id}`;

export const genres = () => `/genres`;

export const genre = (genre) => `${genres()}/${generateSlug(truncateGenreTitle(genre.title))}?id=${genre.id}`;

export const search = (term) => `/search${term ? `?query=${encodeURIComponent(term)}` : ''}`;

export const legal = (anchor = '') => `${get(translate)('legal_file_link')}${anchor}`;

export const shop = () => '/shop';

export const todays = () => '/todays';
export const todaysCollection = (entry) => `/todays/${generateSlug(entry.title)}?id=${entry.id}`;
