<footer>
  <div class="inner">
    <dl class="about">
      <dt>
        <Logo color="#fff" />
      </dt>
      <dd>{$_('tagline')}</dd>
    </dl>
    <dl>
      <dt>{$_('company')}</dt>
      <dd>
        <ul>
          <li><a href={urlFor.legal('#terms-and-conditions')} target="_self">{$_('terms_and_conditions')}</a></li>
          <li><a href={urlFor.legal('#privacy-policy')} target="_self">{$_('privacy_policy')}</a></li>
        </ul>
      </dd>
    </dl>
    <dl>
      <dt>{$_('service')}<dt>
      <dd>
        <ul>
          <li><a href={urlFor.library()}>{$_('library')}</a></li>
          <li><a href={urlFor.home()}>{$_('home')}</a></li>
          <li><a href={IOS_APP_URL} rel="nofollow">{$_('ios_app')}</a></li>
          <li><a href={ANDROID_APP_URL} rel="nofollow">{$_('android_app')}</a></li>
        </ul>
      </dd>
    </dl>
  </div>
</footer>

<style lang="stylus">
  footer
    background: $dark-blue
    color: white
    padding: 30px 20px
    margin-top: 50px

    @media $bp.desktop
      margin-top: 68px

  @media $bp.desktop
    dl
      width: 33%

    .about
      width: 66%

  dt
    font-weight: bold
    margin-bottom: 8px

  dd,
  ul
    margin: 0
    padding: 0

  ul
    list-style: none

  a
    text-decoration: none
    color: $light-grey

    @media $bp.desktop
      &:hover
        color: white

  .inner
    max-width: $main-width
    margin: auto

    @media $bp.desktop
      display: flex
</style>

<script>
  import { _ } from 'svelte-intl';
  import { config } from '../stores';

  import Logo from './Logo.svelte';

  import * as urlFor from '../helpers/urlFor';

  const { ANDROID_APP_URL, IOS_APP_URL } = $config;
</script>
