<svelte:body on:keydown={handleKeyDown} />

{#if !hidden}
  <div
    class="overlay"
    class:auto-size={autoSize}
    transition:fade="{{ duration: 200 }}"
    on:close bind:this={overlay}
    on:click={handleClickOutside}
  >
    <div class="popup" transition:fly="{{ y: isMobile ? 200 : -200, duration: 200 }}">
      <slot />
    </div>
  </div>
{/if}

<style lang="stylus">
  .overlay
    position: fixed
    top: 0
    right: 0
    bottom: 0
    left: 0
    z-index: 5
    background: rgba($color, 0.25)

    &.auto-size
      display: flex
      align-items: center
      justify-content: center

      .popup
        position: static
        width: auto
        height: auto
        max-height 90vh
        margin: auto 0 0
        min-width: 200px
        min-height: 200px

        @media $bp.desktop
          margin: auto

  .popup
    $overflow-auto()
    overscroll-behavior: contain
    box-sizing: border-box
    padding: 16px 24px 24px
    position: absolute
    top: 50%
    left: 50%
    width: 90vw
    height: 90vh
    margin: -45vh 0 0 -45vw
    background: $bg-color
    border-radius: 8px

    @media $bp.desktop
      width: 50vw
      height: 80vh
      margin: -40vh 0 0 -25vw
</style>

<script>
  import { createEventDispatcher } from 'svelte';
  import { fly, fade } from 'svelte/transition';
  import { popup } from '../stores';

  export let hidden = true;
  export let autoSize = false;
  export let isMobile = false;

  const dispatch = createEventDispatcher();

  let overlay;

  function close() {
    popup.hide();
    dispatch('close');
  }

  function handleKeyDown(e) {
    if (e.keyCode === 27) {
      e.preventDefault();
      close();
    }
  }

  function handleClickOutside(e) {
    if (e.target === overlay) {
      close();
    }
  }
</script>
