/**
 * Custom GA events
 */

import { noop } from 'svelte/internal';

const noAnalytics = () => ({
  logEvent: noop
});

const firebaseAnalytics = () => {
  if (!window.firebase) {
    return noAnalytics();
  }

  return window.firebase.analytics();
};

let analytics = firebaseAnalytics;

const logEvent = (...args) => analytics().logEvent(...args);

export const setAnalyticsFactory = (factory) => analytics = factory;

function bookParams(book) {
  const bookAuthor = book.authors && book.authors.map((p) => p.personName).join(', ');

  return {
    bookId: book.id,
    bookTitle: book.title,
    bookAuthor
  };
}

function categoryParams(category) {
  return {
    categoryId: category.id,
    categotyHeader: category.header
  };
}

export const appstoreClicked = () => logEvent('appstore_clicked');
export const googlePlayClicked = () => logEvent('google_play_clicked');
export const signUp = (providerId) => logEvent('user_registered', { providerId });
export const login = (providerId) => logEvent('login', { providerId });
export const bestsellerClicked = (book) => logEvent('bestseller_clicked', bookParams(book));
export const categoryClicked = (caterory, book) => logEvent('category_clicked', { ...categoryParams(caterory), ...bookParams(book) });
export const readInApp = (book) => logEvent('read_in_app', bookParams(book));
export const readExcerpt = (book) => logEvent('read_excerpt', bookParams(book));
export const purchaseCoins = () => logEvent('purchase_coins');
export const purchaseBook = (book) => logEvent('purchase_book', bookParams(book));
export const rentBook = (book) => logEvent('rent_book', bookParams(book));
export const redeemBook = (book) => logEvent('redeem_book', bookParams(book));
