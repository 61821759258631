<style lang="stylus">
  img
    vertical-align middle
</style>

<img
  class="coin-icon"
  alt="coin"
  src="/images/coin@1x.png"
  srcset="/images/coin@2x.png 2x"
  width="24"
  height="24"
/>
