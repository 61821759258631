<button
  class:is-mobile={isMobile}
  on:click|preventDefault={() => user.logIn()}
  title={$_('login')}
  data-cy="login-btn"
>
  {$_('login')}
</button>

<style lang="stylus">
  button
    display: none
    font-family: $font-lab-grotesque
    font-size: 14px
    font-weight: bold
    cursor: pointer
    color: white
    background: $violet
    border: 0
    border-radius: 100px
    padding: 10px 20px
    margin-left: auto
    transition: background-color 0.2s ease-in-out
    white-space: nowrap

    &.is-mobile
      outline: none
      padding: 10px 30px
      margin: 18px -30px -24px
      border-radius: 0 0 8px 8px
      font-weight: normal
      display: block
      width: 100%
      box-sizing: content-box

    @media $bp.desktop
      display: inline-block
      transition: background-color 0.2s ease-in-out

      &:hover
        background: darken($violet, 10%)
</style>

<script>
  import { _ } from 'svelte-intl';
  import { user } from '../stores';

  export let isMobile = false;
</script>
