{#if !hidden}
  <div class={type} transition:fly="{{ y: 100, duration: 200 }}">
    {#if type === 'success'}
      <svg width="24" height="32" viewBox="0 0 24 32" xmlns="http://www.w3.org/2000/svg">
        <path d="M1 2C1 1.44772 1.44772 1 2 1H22C22.5523 1 23 1.44771 23 2V28.3915C23 29.1769 22.136 29.6558 21.47 29.2395L13.59 24.3145C12.6172 23.7065 11.3828 23.7065 10.41 24.3145L2.53 29.2395C1.86395 29.6558 1 29.1769 1 28.3915V2Z" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
      </svg>
    {:else if type === 'error'}
      <svg width="32" height="32" viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
        <line x1="2.12132" y1="3" x2="28" y2="28.8787" stroke-width="3" stroke-linecap="round" />
        <line x1="28" y1="3.12132" x2="2.12132" y2="29" stroke-width="3" stroke-linecap="round" />
      </svg>
    {/if}
    <p>{@html message}</p>
  </div>
{/if}

<style lang="stylus">
  div
    position: fixed
    display: flex
    align-items: center
    left: 24px
    right: 24px
    bottom: 30px
    z-index: 10
    box-sizing: border-box
    background: $bg-color
    box-shadow: 0 5px 20px rgba($color, 0.25)
    border-radius: 8px
    border-left: 14px solid
    padding: 24px 30px

    @media $bp.desktop
      right: auto
      max-width: 446px

  p
    color: $warm-grey
    font-size: 18px
    line-height: 1.4
    margin: 0 0 0 30px

  svg
    flex-basis: 32px
    fill: none

  .success
    border-color: $success-color

    svg
      stroke: @border-color

  .error
    border-color: $error-color

    svg
      stroke: @border-color
</style>

<script>
  import { fly } from 'svelte/transition';

  export let type = 'success';
  export let hidden = true;
  export let message = '';
</script>
