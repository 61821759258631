const API_PATH = '/api';

export const get = async (endpoint) => {
  return await fetch(`${API_PATH}${endpoint}`, {
    method: 'get',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json'
    }
  }).then((res) => res.json());
};

export const post = async (endpoint, data, csrfToken) => {
  const res = await fetch(`${API_PATH}${endpoint}`, {
    method: 'post',
    credentials: 'include',
    body: JSON.stringify(data),
    headers: {
      'Content-Type': 'application/json',
      'x-csrf-token': csrfToken
    }
  });

  if (res.status === 204) {
    return res;
  }

  return await res.json();
};
